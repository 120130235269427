import { GetServerSideProps } from 'next'
import ErrorPage from 'next/error'
import Layout from '../components/layout'
import BlockManager from '../components/shared/BlockManager'
import PageData from '../types/PageData'
import { StrapiGlobal } from '../types/StrapiGlobal'
import { handleRedirection } from '../utils'
import { getLocalizedParams } from '../utils/localize'

interface UniversalsProps {
  global: StrapiGlobal
  pageData: PageData
  preview: boolean
}

const Universals = ({ global, pageData, preview }: UniversalsProps) => {
  if (pageData === null) {
    return <ErrorPage statusCode={404} />
  }

  const { blocks } = pageData.attributes
  return (
    <Layout
      global={global}
      preview={preview}
      seo={pageData?.attributes?.seo}
      locale={pageData?.attributes?.locale}
    >
      {blocks && <BlockManager blocks={blocks} global={global} />}
    </Layout>
  )
}

// This gets called on every request
export const getServerSideProps: GetServerSideProps = async (context) => {
  const { slug, locale } = getLocalizedParams(context.query)
  console.log({ slug, locale })

  if (slug.length === 0) {
    return handleRedirection(context.preview, 'home')
  }

  try {
    // const data = getData(
    //   slug,
    //   locale,
    //   "page",
    //   "collectionType",
    //   context.preview
    // );
    const res = await fetch(
      `${process.env.NEXT_INTERNAL_API_URL}api/pages?filters[slug][$eq]=${slug}&populate=deep,15`
    )
    const json = await res.json()

    if (!json.data.length) {
      return handleRedirection(context.preview, null)
    }

    return {
      props: { pageData: json.data[0], preview: context.preview || null },
    }
  } catch (error) {
    return {
      props: { pageData: null },
    }
  }
}

export default Universals
