/* eslint-disable @typescript-eslint/no-explicit-any */
import dynamic from 'next/dynamic'
import { StrapiGlobal } from '../../types/StrapiGlobal'

const Hero = dynamic(() => import('../blocks/Hero'), {
  ssr: true,
})
const Hero2 = dynamic(() => import('../blocks/Hero2'), {
  ssr: true,
})
const Features = dynamic(() => import('../blocks/Features'), {
  ssr: true,
})
const SplitSection = dynamic(() => import('../blocks/SplitSection'), {
  ssr: true,
})
const Partners = dynamic(() => import('../blocks/Partners'), {
  ssr: true,
})
const Steps = dynamic(() => import('../blocks/Steps'), {
  ssr: true,
})
const Reviews = dynamic(() => import('../blocks/Reviews'), {
  ssr: true,
})
const FunctionsWithImage = dynamic(
  () => import('../blocks/FunctionsWithImage'),
  {
    ssr: true,
  }
)
const FAQ = dynamic(() => import('../blocks/FAQ'), {
  ssr: true,
})
const News = dynamic(() => import('../blocks/News'), {
  ssr: true,
})
const Contact = dynamic(() => import('../blocks/Contact'), {
  ssr: true,
})
const Price = dynamic(() => import('../blocks/Pricing'), {
  ssr: true,
})
const TextSection = dynamic(() => import('../blocks/TextSection'), {
  ssr: true,
})
const Team = dynamic(() => import('../blocks/Team'), {
  ssr: true,
})

const getBlockComponent = (
  {
    __component,
    id,
    ...rest
  }: { __component: string; id: string; [key: string]: any },
  global: StrapiGlobal
) => {
  let Block

  switch (__component) {
    case 'page-section.hero':
      Block = Hero
      break
    case 'page-section.hero2':
      Block = Hero2
      break
    case 'page-section.features':
      Block = Features
      break
    case 'page-section.split-section':
      Block = SplitSection
      break
    case 'page-section.partners':
      Block = Partners
      break
    case 'page-section.steps':
      Block = Steps
      break
    case 'page-section.reviews':
      Block = Reviews
      break
    case 'page-section.functions-with-image':
      Block = FunctionsWithImage
      break
    case 'page-section.faq':
      Block = FAQ
      break
    case 'page-section.posts':
      Block = News
      break
    case 'page-section.contact-form':
      Block = Contact
      break
    case 'page-section.pricing':
      Block = Price
      break
    case 'page-section.text-section':
      Block = TextSection
      break
    case 'page-section.team':
      Block = Team
      break
    default:
      console.log('No component found for', __component)
      Block = null
  }

  return Block ? (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Block key={`block-${id}-${__component}`} {...rest} global={global} />
  ) : null
}

interface BlockManagerProps {
  blocks: Array<{ __component: string; id: string; [key: string]: any }>
  global: StrapiGlobal
}

const BlockManager = ({ blocks, global }: BlockManagerProps) => (
  <>{blocks.map((block) => getBlockComponent(block, global))}</>
)

BlockManager.defaultProps = {
  blocks: [],
}

export default BlockManager
